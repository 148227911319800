import React, { memo, useContext } from 'react'
import SambaVideo from './SambaVideo'
import VideoFile from './VideoFile'
import FilePDF from './FilePDF'
import ImageFile from './ImageFile'
import HandleViewContent from './handleViewContent'

const ContentMedia = ({ video, ...props }) => {
  const { verifyStats } = useContext(HandleViewContent)

  const Medias = () => {
    switch (video.type) {
      case 'SAMBA_MEDIA':
        return <SambaVideo updateStats={verifyStats} contentType={video.contentType} lesson_content_iri={`/lms/lesson_content/${video.id}`} id={video.id} reference={video.samba_media_reference || ''} />
      case 'FILE':
        if (video.file.contenttype === 'image/jpeg' || video.file.contenttype === 'image/png' || video.file.contenttype === 'image/jpg') {
          return <ImageFile updateStats={verifyStats} src={video.file.urlPublica} alt={video.title} />
        } else if (video.file.contenttype === 'application/pdf') {
          return <FilePDF updateStats={verifyStats} id={video.id} url={video.file.urlPublica} />
        } else if (video.file.contenttype === 'video/mp4' || video.file.contenttype === 'video/avi') {
          return <VideoFile updateStats={verifyStats} id={video.id} url={video.file.urlPublica} />
        }
        break
      default:
        return video.file ? video.file.urlPublica : ''
    }
  }

  return (
    <div className='medias'>
      <Medias />
    </div>
  )
}

export default memo(ContentMedia)
