import React, { useState, useEffect } from 'react'

import './styles.css'

const DurationInfo = ({ lesson }) => {
  const [duration, setDuration] = useState(0)

  const getLessonDuration = () => {
    let durationMS = 0

    lesson.lesson_contents.forEach((lessonContent) => {
      durationMS += lessonContent.samba_media_duration || 0
    })

    return millisToMinutesAndSeconds(durationMS)
  }

  const millisToMinutesAndSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000)
    const seconds = ((millis % 60000) / 1000).toFixed(0)
    return seconds === 60
      ? minutes + 1 + ':00'
      : minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }

  useEffect(() => {
    setDuration(getLessonDuration())
  // eslint-disable-next-line
  }, [lesson])

  return (
    <div className='curso-show-info-duration'>
      <p className='curso-show-info-duration-title'>Duração: {duration} MIN</p>
    </div>
  )
}

export default DurationInfo
