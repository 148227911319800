import React, { memo, useState, useEffect } from 'react'
import Script from 'react-load-script'
import api from '../../services/api'
import { userEndPoint } from '../../utils/getUser'
import { getSambaSettings } from '../../services/endpoints/settings'

const SambaVideo = ({ reference, updateStats, lesson_content_iri, id, ...props }) => {
  const [playerKey, setPlayerKey] = useState('')
  const [load, setLoad] = useState(false)
  const [watchedId] = useState(lesson_content_iri)
  const progress = true
  const player = 'player'
  let sambaPlayer = false
  let setProgress = false

  useEffect(() => {
    const getData = async () => {
      const response = await getSambaSettings()
      setPlayerKey(response.value)
    }

    getData()
  }, [])

  if (playerKey.length > 0 && load) {
    const eventListener = (player) => {
      switch (player.event) {
        case 'onLoad':
          break
        case 'onStart':
          if (watchedId === null) {
            api.post('/general/watcheds', {
              user_iri: userEndPoint(),
              progress: 0,
              content_iri: `/lms/lesson_contents/${id}`
            }).then((resp) => {
              console.log('TEMPO INICIADO', resp)
            })
          }
          break
        case 'onListen':
          if (!setProgress && player.eventParam > 1 && parseInt(player.eventParam) % 15 === 0) {
            setProgress = true
            api.post('/general/watcheds', {
              user_iri: userEndPoint(),
              progress: (player.eventParam * 100) / player.duration,
              content_iri: `/lms/lesson_contents/${id}`
            })
            setTimeout(() => {
              setProgress = false
            }, 1000)
          }
          break
        case 'onPause':
          break
        case 'onResume':
          break
        case 'onSeek':
          break
        case 'onCuepoint':
          break
        case 'onMediaView':
          break
        case 'onProgress':
          break
        case 'onFinish':
          api.post('/general/watcheds', {
            user_iri: userEndPoint(),
            progress: 100,
            content_iri: `/lms/lesson_contents/${id}`,
            watched: true
          })
            .then((resp) => {
              updateStats()
            })
          break
        case 'onError':
          break
        default:
          break
      }
    }

    if (!sambaPlayer) {
      sambaPlayer = new window.SambaPlayer(player, {
        ph: playerKey,
        m: reference,
        playerParams: {
          enableShare: false,
          resume: progress
        },
        events: {
          '*': eventListener
        }
      })
    }
  }
  return (
    <>
      <Script
        url='https://player.sambatech.com.br/v3/samba.player.api.js' onLoad={function () {
          setLoad(true)
        }}
      />
      <div className='embed-responsive embed-responsive-16by9' style={{ position: 'relative' }}>
        <div className='embed-responsive-item' id='player' />
      </div>
    </>
  )
}

export default memo(SambaVideo)
