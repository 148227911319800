import React, { useState } from 'react'
import './styles.css'
import { stripTags } from '../../../utils/manipulateString'

const BannerRight = ({ content }) => {
  const [banner] = useState(content || {})
  return (
    <div className='banner-right'>
      <div className='banner-right-info'>
        <p className='sobre-banner-right'>sobre</p>
        <div
          className='banner-right-title'
          dangerouslySetInnerHTML={{ __html: stripTags(banner.title) }}
        />
        <div
          className='banner-right-description'
          dangerouslySetInnerHTML={{ __html: stripTags(banner.description) }}
        />
      </div>
      {banner.file && (
        <div className='banner-right-image-container'>
          <img
            className='banner-right-image'
            src={banner.file.urlPublica}
            alt=''
          />
        </div>
      )}

    </div>
  )
}

export default BannerRight
