import React from 'react'
import { FaRegFilePdf } from 'react-icons/fa'
import './styles.css'

const Material = ({ supportMaterial }) => {
  return (
    <div className='curso-show-info-content-material'>
      {supportMaterial && supportMaterial.length > 0 &&
        supportMaterial.map((material, index) => (
          <a
            href={
              material.support_material.support_material_contents[0].file
                .urlPublica
            }
            target='_blank'
            rel='noopener noreferrer'
            className='curso-show-info-content-material-content'
            key={index}
          >
            <a
              className='curso-show-info-content-material-content-link'
              href={
                material.support_material.support_material_contents[0].file
                  .urlPublica
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaRegFilePdf className='curso-show-info-content-material-content-link-icon' />
            </a>
            <p className='curso-show-info-content-material-content-title'>
              {material.support_material.title}
            </p>
          </a>
        ))}
    </div>
  )
}

export default Material
