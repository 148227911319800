import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString } from '../../utils/manipulateString'

import Pages from './PagesList'
import PageDefault from '../_PageDefault'
import ComponentBase from '../../components/_ComponentBase'

const PageFactory = ({ pageName, page }) => {
  const normalizedPageName = normalizeString(pageName) // Remove acentos, ç -> c, etc...
  const PageToRender = Pages[normalizedPageName] || PageDefault

  return (
    <>
      <PageToRender page={page}>
        <ComponentBase page={page} />
      </PageToRender>
    </>
  )
}

PageFactory.propTypes = {
  pageName: PropTypes.string.isRequired
}

export default PageFactory
