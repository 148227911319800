import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './styles.css'

const Search = () => {
  const [searchKeyword, SetSearchKeyword] = useState('')
  const history = useHistory()

  const handleChange = (e) => {
    e.preventDefault()
    SetSearchKeyword(e.target.value)
  }
  const handleSumit = (e) => {
    e.preventDefault()
    history.push(`/search/${searchKeyword}`)
  }

  return (
    <div className='navbar-search-container'>
      <form className='navbar-search-form' onSubmit={(e) => handleSumit(e)}>
        <input
          className='navbar-search-input'
          type='search'
          name='query'
          placeholder='Pesquisar...'
          id='search'
          required
          onChange={(e) => handleChange(e)}
        />
        <button className='navbar-search-button' type='submit' />
      </form>
    </div>
  )
}

export default Search
