import React, { useState, useEffect } from 'react'

import { getCoursesByKeyword, getPublicCoursesByKeyword } from '../../services/endpoints/lms/courses'
import { getToken } from '../../services/auth'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Breadcrumbs from '../../components/Breadcrumbs'
import Cursos from '../../components/Cursos'

import './styles.css'
import '../_PageBase/styles.css'

const SearchResult = ({ match }) => {
  const keyword = match.params.slug
  const [cursos, setCursos] = useState([])

  useEffect(() => {
    const getData = async () => {
      const response = await (getToken() ? getCoursesByKeyword(keyword) : getPublicCoursesByKeyword(keyword))
      setCursos(response.data || [])
    }

    getData()
  }, [keyword])

  return (
    <>
      <Navbar />
      <Breadcrumbs pages={[{ title: 'Pesquisar', url: '' }]} />
      {cursos &&
        <div className='page-container'>
          <h1 className='page-container-title'>Resultado da pesquisa</h1>
          {cursos.length > 0 &&
            <Cursos content={cursos} />}
        </div>}
      <Footer />
    </>
  )
}

export default SearchResult
