import React, { useState } from 'react'
import jwtDecode from 'jwt-decode'
import { getToken } from '../../services/auth'

const ShowTokenTime = () => {
  const tokenTime = process.env.REACT_APP_TOKENTIME
  const [time, setTime] = useState(0)

  if (tokenTime) {
    const decoded = jwtDecode(getToken())

    setInterval(() => {
      const now = Math.floor(new Date().getTime() / 1000)
      setTime(decoded.exp - now)
    }, 1000)

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          backgroundColor: '#000',
          padding: 5,
          color: '#fff',
          zIndex: 99999,
          fontSize: 10
        }}
      >
        Token Expire: {time}s
      </div>
    )
  }
  return null
}

export default ShowTokenTime
