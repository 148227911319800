import React, { useState } from 'react'
import { getCoursesActive } from '../../../utils/verifyActive'

import './styles.css'

import Carousel from '../../Carousel'
import CursoDestaque from './CursoDestaque'
const CursosDestaque = ({ content = [] }) => {
  const [cursosEmDestaque] = useState(getCoursesActive(content))

  return (
    <>
      {cursosEmDestaque && cursosEmDestaque.length > 0 && (
        <div className='cursos-destaque-container'>
          <h1 className='cursos-destaque-title'>Cursos em Destaque</h1>
          <Carousel
            list={cursosEmDestaque.map((curso, index) => (
              <CursoDestaque curso={curso} key={index} />
            ))}
          />
        </div>
      )}
    </>
  )
}

export default CursosDestaque
