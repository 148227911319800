import React from 'react'
import { removePublicFromStrig, stripTags } from '../../utils/manipulateString'
import './styles.css'

const Trilhas = ({ children, page }) => {
  return (
    <div className='page-container'>
      <h1 className='trilhas-title'>{removePublicFromStrig(page.title)}</h1>
      {page.content &&
        <div className='page-container-content' dangerouslySetInnerHTML={{ __html: stripTags(page.content) }} />}
      {children}
    </div>

  )
}

export default Trilhas
