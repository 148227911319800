import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { stripTags } from '../../../../utils/manipulateString'
import './styles.css'

const CursoDestaque = ({ curso }) => {
  const history = useHistory()
  return (
    <div className='curso-destaque'>
      {curso.image && (
        <div
          className='curso-destaque-image-container'
          onClick={(e) => history.push(`/cursos/${curso.slug}`)}
        >
          {curso.image.urlPublica && (
            <img
              className='curso-destaque-image'
              src={curso.image.urlPublica}
              alt=''
            />
          )}
        </div>
      )}
      {curso.slug && curso.title && (
        <div className='curso-destaque-title'>
          <Link to={`/cursos/${curso.slug}`}>{curso.title}</Link>
        </div>
      )}
      {curso.summary && (
        <div
          className='curso-destaque-text'
          dangerouslySetInnerHTML={{
            __html: stripTags(curso.summary)
          }}
        />
      )}
      <div className='curso-destaque-divider' />
      {curso.slug && (
        <Link
          className='curso-destaque-saiba-mais'
          to={`/cursos/${curso.slug}`}
        >
          SAIBA MAIS
        </Link>
      )}
    </div>
  )
}

export default CursoDestaque
