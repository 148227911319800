import api from '../api'

export const getEndpoints = (endpoints, params = {}) =>
  api.get(endpoints, {
    params: {
      ...params
    }
  })

export const getPublicEndpoints = (endpoints, params = {}) =>
  api.get(`/public${endpoints}`, {
    params: {
      ...params
    }
  })
