const Components = {}

Components.Navbar = require('../Navbar').default
Components.Footer = require('../Footer').default
Components.BannerHome = require('../Home/BannerHome').default
Components.CursosDestaque = require('../Home/CursosDestaque').default
Components.Beneficios = require('../Home/Beneficios').default
Components.Parceiros = require('../Home/Parceiros').default
Components.JoinBanner = require('../Home/JoinBanner').default
Components.BannerLeft = require('../Sobre/BannerLeft').default
Components.BannerRight = require('../Sobre/BannerRight').default
Components.Cursos = require('../Cursos').default
Components.CursoAtual = require('../CursoAtual').default
Components.Anotacoes = require('../Anotacoes').default
Components.Trilhas = require('../Trilhas').default
Components.Forum = require('../Forum').default
Components.Faq = require('../Faq').default
Components.Contato = require('../Contato').default
Components.Favoritos = require('../Favoritos').default
Components.Certificados = require('../Certificados').default

export default Components
