export const getCoursesActive = (courses) => {
  try {
    const coursesActive = courses ? courses.filter(course => course.active) : []
    return coursesActive
  } catch (error) {
    console.error('verifyActive.getCoursesActives: ', courses, ' - error: ', error)
    return courses
  }
}

export const getFavoritesActive = (favorites) => {
  try {
    const favoritesActive = favorites ? favorites.filter(favorite => {
      return (favorite.content && favorite.content.active)
    }) : []
    return favoritesActive
  } catch (error) {
    console.error('verifyActive.getFavoritesActive:', favorites, ' - error: ', error)
    return favorites
  }
}
