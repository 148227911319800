import React, { useState, useEffect } from 'react'
import { FaCheckSquare, FaRegCheckSquare } from 'react-icons/fa'
import { userEndPoint } from '../../../../utils/getUser'
import { getWatched, updateWatcheds } from '../../../../services/endpoints/general/watcheds'
import './styles.css'

const CheckedInfo = ({ lesson }) => {
  const [isActualLessonChecked, setIsActualLessonChecked] = useState(false)

  useEffect(() => {
    const verifyLessonChecked = async () => {
      try {
        const response = await isLessonChecked(lesson)
        setIsActualLessonChecked(response)
      } catch (error) {
        console.error('CheckedInfo.verifyLessonChecked:', error)
      }
    }

    verifyLessonChecked()
  }, [lesson])

  const isLessonChecked = async (lesson) => {
    const lessonContentId = lesson.lesson_contents[0]
      ? lesson.lesson_contents[0].id
      : null

    try {
      const response = await getWatched(`/lms/lesson_contents/${lessonContentId}`)
      return (response.data.length > 0 && response.data[0].watched)
    } catch (error) {
      console.error('CheckedInfo.isLessonChecked:', error)
    }
  }

  const updateLessonStats = async (event) => {
    event.preventDefault()

    const lessonContentId = lesson.lesson_contents[0]
      ? lesson.lesson_contents[0].id
      : null

    const lessonCompleted = {
      user_iri: userEndPoint(),
      progress: 100,
      content_iri: `/lms/lesson_contents/${lessonContentId}`,
      watched: true
    }

    const lessonNotCompleted = {
      user_iri: userEndPoint(),
      progress: 0,
      content_iri: `/lms/lesson_contents/${lessonContentId}`,
      watched: false
    }

    try {
      const body = (await isLessonChecked(lesson) ? lessonNotCompleted : lessonCompleted)

      await updateWatcheds(body)
      setIsActualLessonChecked(!isActualLessonChecked)
    } catch (error) {
      console.error('CheckedInfo.updateLessonStats', error)
    }
  }

  return (
    <div className='curso-show-info-visto'>
      <p className='curso-show-info-title'>Visto</p>
      {isActualLessonChecked
        ? <FaCheckSquare className='curso-show-info-visto-icon' style={{ color: 'green' }} onClick={(e) => updateLessonStats(e)} />
        : <FaRegCheckSquare className='curso-show-info-visto-icon' onClick={(e) => updateLessonStats(e)} />}
    </div>
  )
}

export default CheckedInfo
