import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import Skeleton from 'react-loading-skeleton'
import { stripTags } from '../../../utils/manipulateString'
import './styles.css'

const BannerHome = ({ content }) => {
  const [bannerPrincipal] = useState(content)

  const verifyUrlType = (url, target, buttonText) => {
    if (url && url.includes('www.')) {
      return (
        <a className='banner-principal-button' href={url} target={target}>
          {buttonText}
        </a>
      )
    }
    return (
      <Link className='banner-principal-button' to={url} target={target}>
        {buttonText}
      </Link>
    )
  }

  return (
    <div className='banner-principal'>
      {bannerPrincipal &&
        <>
          <div className='banner-principal-text'>
            <h1 className='banner-principal-title'>
              {bannerPrincipal.title}
            </h1>
            {bannerPrincipal.description &&
              <div className='banner-principal-description' dangerouslySetInnerHTML={{ __html: stripTags(bannerPrincipal.description) }} />}
            {
              bannerPrincipal.url && bannerPrincipal.target && bannerPrincipal.button_text &&
              verifyUrlType(bannerPrincipal.url, bannerPrincipal.target, bannerPrincipal.button_text)
            }
          </div>
          {bannerPrincipal.file && bannerPrincipal.file.urlPublica &&
            <img className='banner-principal-img' src={bannerPrincipal.file.urlPublica} alt='' />}

        </>}
    </div>

  )
}

export default BannerHome
