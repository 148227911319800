import React, { useState, useEffect } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { isTouchDevice } from '../../utils/detectDevice'
import './styles.css'

const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`
}

const Carousel = ({ list, sm = 1, md = 2, bg = 3, xbg = 4 }) => {
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth)
  const [responsive, setResponsive] = useState(0)
  const key = generateKey('carousel')
  const carouselClassName = `carousel-${key}`
  const carouselListClassName = `carousel-list-${key}`
  const carouselListItemClassName = `carousel-list-item-${key}`
  let counter = 0

  window.addEventListener('resize', () => {
    setWindowWidth(document.documentElement.clientWidth)
    const allCarouselList = document.querySelectorAll('.carousel-list')

    if (allCarouselList && !isTouchDevice()) {
      allCarouselList.forEach(carouselList => {
        carouselList.style.transition = 'none'
        carouselList.style.transform = 'translateX(0px)'
        counter = 0
      })
    }
  })

  // setTimeout(() => {
  //   const carouselList = document.querySelector(`.${carouselListClassName}`)

  //   let posInitial, posMoved

  //   if (carouselList) {
  //     carouselList.addEventListener('touchstart', (e) => {
  //       posInitial = e.touches[0].clientX
  //     })

  //     carouselList.addEventListener('touchmove', (e) => {
  //       posMoved = e.touches[0].clientX

  //       console.log(counter)
  //       // Previne mover o carousel se estiver no primeiro ou último item
  //       if (((posMoved > posInitial) && (counter <= 0)) || ((posMoved < posInitial) && (counter + responsive >= list.length))) {
  //         return null
  //       }
  //       carouselList.style.transform = `translateX(${posMoved - posInitial}px)`
  //     })

  //     carouselList.addEventListener('touchend', (e) => {
  //       if (posMoved > posInitial) {
  //         carouselBackClick()
  //       } else {
  //         carouselForwardClick()
  //       }
  //     })
  //   }
  // }, 1000)

  useEffect(() => {
    const carousel = document.querySelector(`.${carouselClassName}`)
    const itens = document.querySelectorAll(`.${carouselListItemClassName}`)

    if (carousel && itens) {
      const carouselWidth = carousel.clientWidth
      let divider = 0
      if (carouselWidth <= 550) {
        divider = sm
        setResponsive(sm)
      } else if (carouselWidth <= 768) {
        divider = md
        setResponsive(md)
      } else if (carouselWidth <= 991) {
        divider = bg
        setResponsive(bg)
      } else {
        divider = xbg
        setResponsive(xbg)
      }

      divider > 0 &&
        itens.forEach((item) => { item.style.minWidth = `${carouselWidth / divider}px` })
    }
    // eslint-disable-next-line
  }, [list, windowWidth])

  const carouselBackClick = (e) => {
    if (e) e.preventDefault()

    if (counter <= 0) return
    const carouselList = document.querySelector(`.${carouselListClassName}`)
    const item = document.querySelector(`.${carouselListItemClassName}`)
    if (carouselList && item) {
      counter--
      carouselList.style.transition = 'transform 0.4s ease-in-out'
      carouselList.style.transform = `translateX(${-item.clientWidth * counter}px)`
    }
  }

  const carouselForwardClick = (e) => {
    if (e) e.preventDefault()

    if (counter + responsive >= list.length) return
    const carouselList = document.querySelector(`.${carouselListClassName}`)
    const item = document.querySelector(`.${carouselListItemClassName}`)

    if (carouselList && item) {
      counter++
      carouselList.style.transition = 'transform 0.4s ease-in-out'
      carouselList.style.transform = `translateX(${-item.clientWidth * counter}px)`
    }
  }

  return (
    <div className='carousel-container'>
      <IoIosArrowBack
        className='carousel-arrow carousel-arrow-back'
        onClick={(e) => carouselBackClick()}
      />
      <div className={`carousel ${carouselClassName}`}>
        <div className={`carousel-list ${carouselListClassName}`}>
          {list.map((item, index) => (
            <div className={`carousel-list-item ${carouselListItemClassName}`} key={index}>
              {item}
            </div>
          ))}
        </div>
      </div>
      <IoIosArrowForward
        className='carousel-arrow carousel-arrow-forward'
        onClick={(e) => carouselForwardClick()}
      />
    </div>
  )
}

export default Carousel
