import api from '../../api'

export const getCourses = () => {
  return api.get('/lms/courses?active=true')
}

export const getPublicCourses = () => {
  return api.get('/public/lms/courses?active=true')
}

export const getCoursesById = (id) => {
  return api.get(`/lms/courses/${id}`)
}

export const getCoursesBySlug = (slug) => {
  return api.get(`/lms/courses?slug=${slug}&active=true`)
}
export const getPublicCoursesBySlug = (slug) => {
  return api.get(`/public/lms/courses?slug=${slug}&active=true`)
}

export const getHighlightCourses = () => {
  return api.get('/lms/courses?highlight=true&active=true')
}

export const getPublicHighlightCourses = () => {
  return api.get('/public/lms/courses?highlight=true&active=true')
}

export const getCoursesByKeyword = (keyword) => {
  return api.get(`/lms/courses?course_search=${keyword}&active=true`)
}

export const getPublicCoursesByKeyword = (keyword) => {
  return api.get(`/public/lms/courses?course_search=${keyword}&active=true`)
}

export const getCompletedCourses = () => {
  return api.get('lms/course_stats?course_status=CONCLUIDO&active=true')
}

export const fetchCoursesInternal = (route, params) => api.get(`${route}`, {
  params: {
    ...params
  }
})
