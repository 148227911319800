import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import { nameFormat } from '../../../utils/manipulateString'
import { getToken, redirectToLogin, redirectToSignUp } from '../../../services/auth'
import { getCurrentUser } from '../../../utils/getUser'

import Dropdown from './Dropdown'
import './styles.css'

const userMenu = [
  {
    title: 'Perfil',
    url: '/perfil',
    target: '_self'
  },
  {
    title: 'Favoritos',
    url: '/favoritos',
    target: '_self'
  },
  {
    title: 'Certificados',
    url: '/certificados',
    target: '_self'
  },
  {
    title: 'Sair',
    url: '',
    target: '_self'
  }
]

const Menu = ({ menu }) => {
  const [user] = useState(getCurrentUser())

  const showMenu = () => {
    const menu = document.querySelector('.navbar-menu')
    const menuLinks = document.querySelectorAll('.navbar-menu-link')
    const hamburger = document.querySelector('.hamburger-container')

    menu.classList.toggle('navbar-menu-visible')
    menuLinks.forEach((link) => {
      link.classList.toggle('navbar-menu-link-visible')
    })
    hamburger.classList.toggle('hamburger-active')
  }

  return (
    <>
      <nav role='navigation' className='navbar-menu'>
        {menu.map((item, index) =>
          item.menu_items.length === 0 ? (
            <Link className='navbar-menu-link' to={item.url} key={index}>
              {item.title}
            </Link>
          ) : (
            <div className='navbar-menu-link' key={index}>
              <Dropdown title={item.title} childrens={item.menu_items} key={index} />
            </div>
          )
        )}
        {!getToken() ? (
          <>
            <div className='navbar-menu-link' onClick={() => redirectToSignUp()}>
              Inscreva-se
            </div>
            <div className='navbar-menu-link' onClick={() => redirectToLogin()}>
              Login
            </div>
          </>
        ) : (
          <div className='navbar-menu-link'>
            {user.name && <Dropdown title={nameFormat(user.name)} childrens={userMenu} />}
          </div>
        )}
      </nav>
      <div className='hamburger-container' id='hamburger-container' onClick={() => showMenu()}>
        <GiHamburgerMenu className='hamburger' />
      </div>
    </>
  )
}

export default Menu
