import React, { useState, useEffect } from 'react'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { getToken } from '../../../../services/auth'
import { getFavoriteByCourseAndUserId, updateFavorites } from '../../../../services/endpoints/general/evaluations'
import { getCurrentUser } from '../../../../utils/getUser'
import './styles.css'

const FavoriteInfo = ({ course }) => {
  const [favoriteCourse, setFavoriteCourse] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getFavoriteByCourseAndUserId(getCurrentUser().id, course.id)
        setFavoriteCourse(response.data)
      } catch (error) {
        console.error('favoriteInfo.getData:', error)
      }
    }
    getToken() && getData()
  }, [course])

  const updateFavoritesNow = async (event) => {
    event.preventDefault()

    const body = {
      user_iri: `/users/${getCurrentUser().id}`,
      content_iri: `/lms/courses/${course.id}`,
      value: '1',
      type: 'FAVORITE'
    }

    try {
      await updateFavorites(body)
      if (favoriteCourse.length === 0) {
        setFavoriteCourse(favoriteCourse => [...favoriteCourse, 1])
      } else {
        setFavoriteCourse([])
      }
    } catch (error) {
      alert(error)
    }
  }

  return (
    <div className='curso-show-info-favorito'>
      <p className='curso-show-info-title'>Favoritos</p>

      {favoriteCourse.length === 0
        ? <FaRegHeart className='curso-show-info-favorito-icon' onClick={(e) => updateFavoritesNow(e)} />
        : <FaHeart className='curso-show-info-favorito-icon' style={{ color: 'red' }} onClick={(e) => updateFavoritesNow(e)} />}

    </div>
  )
}

export default FavoriteInfo
