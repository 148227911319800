import React, { useState, useEffect } from 'react'

import { getToken } from '../../services/auth'
import { getEndpoints, getPublicEndpoints } from '../../services/endpoints/getEndpoints'
import { passToPascalCase } from '../../utils/manipulateString'

import ComponentFactory from '../_ComponentFactory'

const ComponentBase = ({ children, page }) => {
  const [containers, setContainers] = useState([])

  const getAllEndpoints = (blocks) => {
    const endPoints = []
    blocks.forEach(block => {
      // eslint-disable-next-line
      let api = (!getToken() && block.block.endpoint.split('/')[1] !== 'cms') ? getPublicEndpoints : getEndpoints
      endPoints.push(api(block.block.endpoint))
    })

    return endPoints
  }

  const saveContainers = (blocks, resultEndPoints) => {
    blocks.forEach((block, index) => {
      const blockContent = resultEndPoints.length > 0 ? resultEndPoints[index].data : {}
      const { container } = block
      const componentName = passToPascalCase(block.block.name)

      const content = {
        container: container,
        componentName: componentName,
        content: blockContent
      }

      setContainers(containers => [...containers, content])
    })
  }

  useEffect(() => {
    const loadComponents = async () => {
      let resultEndPoints = []
      try {
        const endPoints = getAllEndpoints(page.pages_blocks)
        resultEndPoints = await Promise.all(endPoints) || []
        saveContainers(page.pages_blocks, resultEndPoints)
      } catch (err) {
        saveContainers(page.pages_blocks, resultEndPoints)
      }
    }

    loadComponents()
  }, [page])
  return (
    <>
      <header>
        {containers.filter(({ container }) => container === 'header').map(({ componentName, content }, index) => (
          <React.Fragment key={`header-container-${index}`}>
            {componentName && content &&
              <ComponentFactory
                key={`header-${index}`}
                componentName={componentName}
                content={content}
              />}
          </React.Fragment>
        ))}
      </header>

      <main>

        {containers.filter(({ container }) => container === 'main').map(({ componentName, content }, index) => (
          <React.Fragment key={`main-container-${index}`}>
            {componentName && content &&
              <ComponentFactory
                key={`main-${index}`}
                componentName={componentName}
                content={content}
              />}
          </React.Fragment>
        ))}
      </main>

      {children}

      <footer>
        {containers.filter(({ container }) => container === 'footer').map(({ componentName, content }, index) => (
          <React.Fragment key={`footer-container-${index}`}>
            {componentName && content &&
              <ComponentFactory
                key={`footer-${index}`}
                componentName={componentName}
                content={content}
              />}
          </React.Fragment>
        ))}
      </footer>
    </>
  )
}

export default ComponentBase
