import api from '../../api'

export const getComments = () => {
  return api.get('/general/comments')
}

export const getCommentsByCourseId = (id) => {
  return api.get(`/general/comments?parent_iri=/lms/courses/${id}&order[created_at]=desc`)
}

export const getPublicCommentsByCourseId = (id) => {
  return api.get(`/public/general/comments?parent_iri=/lms/courses/${id}&order[created_at]=desc`)
}

export const postComment = (body) => {
  api.post('/general/comments', body)
}
