import React from 'react'
import './styles.css'

const Parceiro = ({ parceiro }) => {
  const handleClickParceiro = (event, parceiroUrl) => {
    event.preventDefault()
    window.open(parceiroUrl, '_blank')
  }

  return (
    <div className='parceiro'>
      {parceiro && parceiro.file && (
        <img
          className='parceiro-img'
          src={parceiro.file.urlPublica}
          alt=''
          onClick={(e) => handleClickParceiro(e, parceiro.url)}
        />
      )}
    </div>
  )
}

export default Parceiro
