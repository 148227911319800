import React from 'react'
import Aula from './Aula'
import './styles.css'

const Aulas = ({ lessons, actualLesson }) => {
  return (
    <div className='curso-show-info-content-aulas curso-show-info-content-active'>
      {
        lessons.map((lesson, index) => (
          <div key={index}>
            <Aula lesson={lesson} lessonIndex={index} actualLesson={actualLesson} />
            <div className='curso-show-info-content-divider' />
          </div>
        ))
      }
    </div>
  )
}

export default Aulas
