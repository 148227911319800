import React, { useState } from 'react'
import Carousel from '../../Carousel'
import Parceiro from './Parceiro'
import './styles.css'

const Parceiros = ({ content }) => {
  const [banners] = useState(content)

  return (
    <>
      {banners.banners && banners.banners.length > 0 && (
        <div className='parceiros'>
          <div className='parceiros-container'>
            <h1 className='parceiros-title'>{banners.name}</h1>

            <Carousel
              list={banners.banners.map((parceiro, index) => (
                <Parceiro parceiro={parceiro} key={index} />
              ))}
              sm={1}
              md={3}
              bg={4}
              xbg={6}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Parceiros
