import React, { useState, useEffect, useContext } from 'react'
import { FaCheckSquare, FaRegCheckSquare } from 'react-icons/fa'

import { getToken } from '../../../../../services/auth'
import { getWatched } from '../../../../../services/endpoints/general/watcheds'

import VerifyStatsContext from '../../../VerifyStatsContext'
import DurationInfo from '../../../InfoActualCourse/DurationInfo'

import './styles.css'
import NoProfilePicture from '../../../../../assets/images/no-profile-picture.png'

const Aula = ({ lesson = {}, lessonIndex, actualLesson }) => {
  const { lesson_contents, title } = lesson
  const [isLessonChecked, setIsLessonChecked] = useState(false)
  const { verifyStats } = useContext(VerifyStatsContext)

  useEffect(() => {
    const checkLesson = async () => {
      const lessonContentId = lesson.lesson_contents[0]
        ? lesson.lesson_contents[0].id
        : null
      try {
        if (getToken()) {
          const response = await getWatched(`/lms/lesson_contents/${lessonContentId}`)
          setIsLessonChecked(response.data.length > 0 && response.data[0].watched)
        }
      } catch (error) {
        console.error('Aula.checkLesson:', error)
      }
    }
    checkLesson()
  }, [lesson, actualLesson])

  const isActualLesson = () => {
    return lessonIndex === actualLesson
  }

  return (
    <div
      className='curso-show-info-content-aulas-aula'
    >
      {lesson_contents && lesson_contents.length > 0 &&
        <img
          className='curso-show-info-content-aula-thumbnail'
          src={
            lesson_contents[0].thumbnail
              ? lesson_contents[0].thumbnail.urlPublica
              : NoProfilePicture
          }
          alt=''
          onClick={(e) => verifyStats(lessonIndex)}
        />}
      <div className='curso-show-info-content-aula-info'>
        {title &&
          <h1 className='curso-show-info-content-aula-info-titulo' onClick={(e) => verifyStats(lessonIndex)}>
            {title}
          </h1>}
        <div className='curso-show-info-content-aula-info-container'>
          {lesson &&
            <DurationInfo lesson={lesson} />}
          {getToken() && !isActualLesson() &&
        (isLessonChecked
          ? <FaCheckSquare className='curso-show-info-content-visto-icon' style={{ color: 'green', cursor: 'default' }} />
          : <FaRegCheckSquare className='curso-show-info-content-visto-icon' style={{ cursor: 'default' }} />)}
        </div>
      </div>
    </div>
  )
}

export default Aula
