const Pages = {}

Pages.Home = require('../Home').default
Pages.Sobre = require('../Sobre').default
Pages.CursoAtual = require('../CursoAtual').default
Pages.Trilhas = require('../Trilhas').default
Pages.TrilhaAtual = require('../TrilhaAtual').default
Pages.Forum = require('../Forum').default
Pages.Contato = require('../Contato').default

export default Pages
