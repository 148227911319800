import React from 'react'
import { stripTags } from '../../../../utils/manipulateString'
import './styles.css'
import NoProfilePicture from '../../../../assets/images/no-profile-picture.png'

const Professor = ({ instructor = {} }) => {
  return (

    <div className='curso-show-info-content-professor'>
      {instructor &&
        <div className='curso-show-info-content-professor-card'>
          {instructor.avatar &&
            <img
              className='curso-show-info-content-professor-card-image'
              src={instructor.avatar.urlPublica ? instructor.avatar.urlPublica : NoProfilePicture}
              alt=''
            />}
          {instructor.name &&
            <h3 className='curso-show-info-content-professor-card-name'>
              {instructor.name}
            </h3>}
          <div className='curso-show-info-content-professor-card-tag'>
        Professor
          </div>
          {instructor.description &&
            <div
              className='curso-show-info-content-professor-card-description'
              dangerouslySetInnerHTML={{ __html: stripTags(instructor.description) }}
            />}
        </div>}
    </div>
  )
}

export default Professor
