import React from 'react'
import { getToken } from '../../../services/auth'

import FavoriteInfo from './FavoriteInfo'
import CheckedInfo from './CheckedInfo'
import AulasInfo from './AulasInfo'
import DurationInfo from './DurationInfo'

import './styles.css'

const InfoActualCourse = ({ course = {}, actualLesson }) => {
  const { lessons } = course

  return (
    <div className='curso-show-info'>
      <div className='curso-show-info-detail'>
        {getToken() && (
          <>
            <FavoriteInfo course={course} />
            <CheckedInfo lesson={lessons[actualLesson]} />
          </>
        )}
        <AulasInfo lessons={lessons} />
        <DurationInfo lesson={lessons[actualLesson]} />
      </div>

    </div>
  )
}

export default InfoActualCourse
