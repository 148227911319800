import Cookie from 'js-cookie'

export const getToken = () => {
  return Cookie.get(process.env.REACT_APP_TOKEN)
}

const getSigninUrl = process.env.REACT_APP_SIGNIN_URL

export const redirectToLogin = (msg = '') => {
  localStorage.clear()
  Cookie.remove(process.env.REACT_APP_TOKEN)
  window.location = `${getSigninUrl}?logout=${btoa(document.location.origin)}${msg ? `&msg=${msg}` : ''}`
}

export const redirectToSignUp = (msg = '') => {
  localStorage.clear()
  Cookie.remove(process.env.REACT_APP_TOKEN)
  window.location = `${getSigninUrl}?signUp=${btoa(document.location.origin)}${msg ? `&msg=${msg}` : ''}`
}

export const refreshLogin = () => {
  window.location = `${getSigninUrl}?login=${btoa(document.location.href)}`
}

export const signinRedirect = (detail) => {
  switch (detail) {
    case 'expired_token':
      refreshLogin()
      break
    case 'not_found_token':
    case 'invalid_token':
    default:
      redirectToLogin()
      break
  }
}
