import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { GuardedRoute, GuardProvider } from 'react-router-guards'

import { redirectToLogin, getToken } from './services/auth'
import { getMe } from './services/endpoints/general/users'

import PageBase from './pages/_PageBase'
import Profile from './pages/Profile'
import SearchResult from './pages/SearchResult'
import CursoAtual from './pages/CursoAtual'
import TrilhaAtual from './pages/TrilhaAtual'

const requiredLogin = async (to, from, next) => {
  try {
    const response = await getMe()
    localStorage.setItem(`${process.env.REACT_APP_PREFIX}.user`, JSON.stringify(response.data))

    next()
  } catch (error) {
    redirectToLogin()
  }
  next.redirect('/')
}

const Routes = () => (
  <Router>
    <GuardProvider>
      <Switch>
        {(getToken() === null || getToken() === undefined) &&
          <GuardedRoute exact path='/' component={PageBase} />}
        <GuardedRoute exact path='/cursos/:slug' component={CursoAtual} />
        <GuardedRoute exact path='/trilhas/:slug' component={TrilhaAtual} />
        <GuardedRoute exact path='/search/:slug' component={SearchResult} />
        {(getToken() === null || getToken() === undefined) &&
          <GuardedRoute exact path='/:slug' component={PageBase} />}

        <GuardProvider guards={[requiredLogin]}>
          <Switch>
            <GuardedRoute exact path='/' component={PageBase} />
            <GuardedRoute exact path='/perfil' component={Profile} />
            <GuardedRoute exact path='/:slug' component={PageBase} />
          </Switch>
        </GuardProvider>
      </Switch>
    </GuardProvider>
  </Router>
)

export default Routes
