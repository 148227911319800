import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getPagesBySlug } from '../../services/endpoints/cms/pages'
import { passToPascalCase, removePublicFromStrig } from '../../utils/manipulateString'
import ShowTokenTime from '../../components/ShowTokenTime'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import PageFactory from '../_PageFactory'
import './styles.css'

const PageBase = ({ match, ...props }) => {
  const [page, setPage] = useState({})
  const history = useHistory()
  const slug = match.params.slug ? match.params.slug.toLowerCase() : 'home'

  useEffect(() => {
    const loadPage = async () => {
      const response = await getPagesBySlug(slug)
      response.data.length < 1 && history.push('/')
      setPage(response.data[0])
    }

    loadPage()
  }, [slug, history])

  const passToComponentName = (string) => {
    const componentName = removePublicFromStrig(string)
    return (passToPascalCase(componentName))
  }

  return (
    <>
      <ShowTokenTime />
      <Navbar />
      {page.title &&
        <PageFactory pageName={passToComponentName(page.title)} page={page} />}
      <Footer />
    </>
  )
}

export default PageBase
