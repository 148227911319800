import React, { useState } from 'react'
import { stripTags } from '../../../utils/manipulateString'
import './styles.css'

const JoinBanner = ({ content }) => {
  const [banner] = useState(content)

  return (
    <>
      {banner && banner.file &&
        <div className='joinbanner-container'>
          <img className='joinbanner-banner' src={banner.file.urlPublica || ''} alt='' />
          <div className='joinbanner-text-container'>
            <div className='joinbanner-text' dangerouslySetInnerHTML={{ __html: stripTags(banner.description) }} />
            <div className='hero-divider-deco cta-divider-deco' />
          </div>
          <div className='joinbanner-title-container'>
            <div className='joinbanner-title'>{banner.title}</div>
          </div>
        </div>}
    </>
  )
}

export default JoinBanner
