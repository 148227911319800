const changeMaskPhone = (value) => {
  if (value !== undefined && value !== null) {
    value = value.replace(/\D/g, '');

    if (value.slice(4, 5) === "9") {
      return "+55 (99) 99999-9999";
    }
  }
  return "+55 (99) 9999-9999";
};

export const makeMask = (value, mask = '', maskType = '') => {
  switch (maskType) {
    case 'phone':
      return changeMaskPhone(value);
    case 'other':
      return '';
    default:
      return mask;
  }
};
