import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { stripTags } from '../../../utils/manipulateString'

import './styles.css'
import NoProfile from '../../../assets/images/no-profile-picture.png'

const Curso = ({ curso, children }) => {
  const { active, image, title, summary, slug } = curso
  const history = useHistory()

  return (
    <>
      {active &&
        <div className='curso'>
          {children}

          {image && (
            <div className='curso-image-container'>
              <img
                className='curso-image'
                src={image.urlPublica !== ''
                  ? image.urlPublica
                  : NoProfile}
                alt=''
                onClick={e => history.push(`/cursos/${slug}`)}
              />
            </div>
          )}

          {title && slug &&
            <Link className='curso-title' to={`/cursos/${slug}`}>
              {title}
            </Link>}

          {summary &&
            <div
              className='curso-text'
              dangerouslySetInnerHTML={{ __html: stripTags(summary) }}
            />}
          {/* <div className='curso-divider' /> */}
          {slug &&
            <Link className='curso-saiba-mais' to={`/cursos/${slug}`}>
                  SAIBA MAIS
            </Link>}
        </div>}
    </>
  )
}

export default Curso
