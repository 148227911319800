import React from 'react'
import PropTypes from 'prop-types'
import Components from './ComponentList'

const ComponentFactory = ({ componentName, content }) => {
  const ComponentToRender = Components[componentName] || {}
  return <ComponentToRender content={content} />
}

ComponentFactory.propTypes = {
  componentName: PropTypes.string.isRequired
}

export default ComponentFactory
