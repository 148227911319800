import React from 'react'
import Aulas from './Aulas'
import Material from './Material'
import Professor from './Professor'
import './styles.css'

const MenuActualCourse = ({ course, actualLesson }) => {
  const { lessons, instructor } = course
  const handleMenuClick = (event) => {
    event.preventDefault()
    const menuAulas = document.querySelector('.curso-show-info-menu-aulas')
    const menuMaterial = document.querySelector('.curso-show-info-menu-material')
    const menuProfessor = document.querySelector('.curso-show-info-menu-professor')
    const aulas = document.querySelector('.curso-show-info-content-aulas')
    const material = document.querySelector('.curso-show-info-content-material')
    const professor = document.querySelector('.curso-show-info-content-professor')

    menuAulas.classList.remove('curso-show-info-menu-active')
    menuMaterial.classList.remove('curso-show-info-menu-active')
    menuProfessor.classList.remove('curso-show-info-menu-active')
    aulas.classList.remove('curso-show-info-content-active')
    material.classList.remove('curso-show-info-content-active')
    professor.classList.remove('curso-show-info-content-active')

    event.target.classList.add('curso-show-info-menu-active')

    if (event.target === menuAulas) {
      aulas.classList.add('curso-show-info-content-active')
    } else if (event.target === menuMaterial) {
      material.classList.add('curso-show-info-content-active')
    } else if (event.target === menuProfessor) {
      professor.classList.add('curso-show-info-content-active')
    }
  }

  return (
    <div className='menu-cursos-show'>
      <div className='curso-show-info-detail-2'>
        <nav className='curso-show-info-menu'>
          <div
            className='curso-show-info-menu-aulas curso-show-info-menu-active'
            onClick={(e) => handleMenuClick(e)}
          >
            Outras aulas
          </div>
          <div
            className='curso-show-info-menu-material'
            onClick={(e) => handleMenuClick(e)}
          >
            Material de apoio
          </div>
          <div
            className='curso-show-info-menu-professor'
            onClick={(e) => handleMenuClick(e)}
          >
            Sobre o professor
          </div>
        </nav>
      </div>

      <Aulas lessons={lessons} actualLesson={actualLesson} />
      <Material lesson={lessons[actualLesson]} />
      <Professor instructor={instructor} />

    </div>
  )
}

export default MenuActualCourse
