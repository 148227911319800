import api from '../../api'

export const getPages = () => {
  return api.get('/cms/pages')
}

export const getPagesBySlug = (slug, params = {}) =>
  api.get('/cms/pages', {
    params: {
      slug,
      ...params
    }
  })
