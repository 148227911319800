import api from '../../api'

export const getTrails = () => {
  return api.get('/lms/trails')
}

export const getPublicTrails = () => {
  return api.get('/public/lms/trails')
}

export const getTrailsById = (id) => {
  return api.get(`/lms/trails/${id}`)
}

export const getPublicTrailsById = (id) => {
  return api.get(`/public/lms/trails/${id}`)
}
