import React, { useState, useContext } from 'react'
import { IoMdClose } from 'react-icons/io'
import { redirectToLogin, redirectToSignUp } from '../../../services/auth'
import BlockedModalContext from '../BlockedModalContext'
import './styles.css'

const BlockedContentModal = ({ modalState }) => {
  const [modalActive] = useState(modalState)
  const { updateModalStats } = useContext(BlockedModalContext)

  const closeModal = (event) => {
    event.preventDefault()
    const element = event.target
    const outsideModal = document.querySelector('.blocked-content-modal-container')
    const closeButton = document.querySelector('.blocked-content-modal-close')

    const correctElement = element === outsideModal || element === closeButton
    correctElement && updateModalStats()
  }

  return (
    <>
      {modalActive &&
        <div className='blocked-content-modal-container' onClick={(e) => closeModal(e)}>
          <div className='blocked-content-modal'>
            <div className='blocked-content-modal-close-container'>
              <IoMdClose className='blocked-content-modal-close' onClick={(e) => closeModal(e)} />
            </div>
            <div className='blocked-content-modal-text-container'>
              <h1 className='blocked-content-modal-text-title'>Aula indisponível!</h1>
              <p className='blocked-content-modal-text-summary'>Para ter acesso aos conteúdos desta aula, faça sua inscrição.</p>
            </div>

            <div className='blocked-content-modal-button-container'>
              <button className='blocked-content-modal-button' onClick={(e) => redirectToLogin()}>Login</button>
              <button className='blocked-content-modal-button' onClick={(e) => redirectToSignUp()}>Inscreva-se</button>
            </div>
          </div>
        </div>}
    </>
  )
}

export default BlockedContentModal
