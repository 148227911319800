import React, { useState, useEffect } from 'react'
import {
  getCoursesBySlug,
  getPublicCoursesBySlug
} from '../../services/endpoints/lms/courses'
import { getToken } from '../../services/auth'
import { isEmpty } from '../../utils/isEmpty'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Breadcrumbs from '../../components/Breadcrumbs'
import ActualCourse from '../../components/CursoAtual'
import Anotacoes from '../../components/Anotacoes'

import './styles.css'

const CursoAtual = ({ children, match, courseSlug = null }) => {
  const slug = courseSlug === null ? match.params.slug : courseSlug
  const [course, setCourse] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const updateCourses = async () => {
      try {
        const response = await (getToken()
          ? getCoursesBySlug(slug)
          : getPublicCoursesBySlug(slug))
        setCourse(response.data[0] || {})
        setLoading(false)
      } catch (error) {
        console.error('CursoAtual.updateCourses:', error)
      }
    }
    updateCourses()
    return () => {
      setLoading(true)
    }
  }, [slug])

  if (loading) return null

  return (
    <>
      <Navbar />

      <Breadcrumbs
        pages={[
          {
            title: 'Cursos',
            url: '/cursos'
          },
          {
            title: `${course.title}`
          }
        ]}
      />
      {!isEmpty(course) && (
        <div className='page-container'>
          <ActualCourse actualCourse={course} />
          <Anotacoes idCurso={course.id} />
        </div>
      )}
      <Footer />
    </>
  )
}

export default CursoAtual
