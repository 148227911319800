import React, { useState } from 'react'
import { getToken } from '../../../services/auth'
import BlockedContentModal from '../BlockedContentModal'
import BlockedModalContext from '../BlockedModalContext'
import './styles.css'

const BlockedContentLayer = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const updateModalStats = () => {
    setModalOpen(!modalOpen)
  }

  return (
    <>
      {!getToken() &&
        <>
          <div className='curso-show-actual-lesson-content-no-login' onClick={(e) => updateModalStats()} />
          <BlockedModalContext.Provider value={{ updateModalStats }}>
            <BlockedContentModal key={String(modalOpen)} modalState={modalOpen} />
          </BlockedModalContext.Provider>
        </>}
    </>
  )
}

export default BlockedContentLayer
