import React from 'react'
import './styles.css'
const Home = ({ children, page }) => {
  return (
    <div className='page-container' id='home-container' style={{ minHeight: '100vh' }}>
      {children}
    </div>
  )
}

export default Home
