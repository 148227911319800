import React, { useState, useEffect } from 'react'
import { AiOutlineLock, AiOutlineUnlock, AiOutlineCheckCircle } from 'react-icons/ai'

import { getTrailsById, getPublicTrailsById } from '../../services/endpoints/lms/trails'
import { getCompletedCourses } from '../../services/endpoints/lms/courses'
import { getToken } from '../../services/auth'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Breadcrumbs from '../../components/Breadcrumbs'
import CursoAtual from '../../components/CursoAtual'

import './styles.css'

const TrilhaAtual = ({ match }) => {
  const idTrail = match.params.slug
  const [trail, setTrail] = useState([])
  const [completedCourses, setCompletedCourses] = useState([])
  const [actualCourse, setActualCourse] = useState(0)

  useEffect(() => {
    const getData = async () => {
      const trailResponse = await (getToken() ? getTrailsById(idTrail) : getPublicTrailsById(idTrail))
      const completedCoursesResponse = await getToken() ? getCompletedCourses() : []

      setTrail(trailResponse.data || [])
      setCompletedCourses(completedCoursesResponse.data || [])
    }
    getData()
  }, [idTrail])

  const verifyComplete = (id) => {
    if (completedCourses !== null && completedCourses.filter(item => item.course.id === id).length > 0) {
      return true
    } else {
      return false
    }
  }

  const verifyStatus = (i) => {
    const course = trail.trails_courses[i]
    const watched = verifyComplete(course.course.id)
    const unlocked = i === 0 || (trail[i - 1] !== undefined && verifyComplete(trail[i - 1].course.id))

    if (watched && unlocked) {
      return <AiOutlineUnlock className='trilha-show-icon' />
    } else if (watched) {
      return <AiOutlineCheckCircle className='trilha-show-icon' style={{ color: 'green' }} />
    }

    return <AiOutlineLock className='trilha-show-icon' />
  }

  return (
    <>
      <Navbar />
      <Breadcrumbs />
      {!trail.trails_courses || !trail.trails_courses.length > 0
        ? (
          <div className='trila-show-container' style={{ minHeight: '60vh' }}>
            {/* <h1 className='trila-show-title'>Nenhum curso disponível</h1> */}
          </div>
        )

        : (
          <>
            <CursoAtual actualCourse={trail.trails_courses[actualCourse].course} />
            <div className='trila-show-container'>

              <h1 className='trila-show-title'>{trail.title}</h1>
              <div className='trila-show-cursos'>
                {trail.trails_courses.map((curso, index) => (
                  <div className='trila-show-curso' key={index}>
                    {verifyStatus(index)}
                    {curso.course.image &&
                      <div className='trila-show-curso-image'>
                        <img src={curso.course.image.urlPublica} alt='' />
                      </div>}
                    <div className='trila-show-curso-title' onClick={() => setActualCourse(index)}>
                      {curso.course.title}
                    </div>
                    <div className='divider' />
                    <div className='trila-show-curso-saiba-mais' onClick={() => setActualCourse(index)}>
                            SAIBA MAIS
                    </div>
                  </div>

                ))}
              </div>
            </div>
          </>
        )}
      <Footer />
    </>
  )
}

export default TrilhaAtual
