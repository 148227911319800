import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getToken, redirectToLogin } from '../../services/auth'
import { getBannersById } from '../../services/endpoints/cms/banners'
import { getMenusById } from '../../services/endpoints/cms/menus'
import './styles.css'

const idBanner = 18
const idMenuFooter = 6
const idMenuRedeSocial = 7

const Footer = () => {
  const [banner, setBanner] = useState({})
  const [menuFooter, setMenuFooter] = useState([])
  const [menuRedeSocial, setMenuRedeSocial] = useState([])

  useEffect(() => {
    const getData = async () => {
      const tempBanner = await getBannersById(idBanner)
      const tempMenuFooter = await getMenusById(idMenuFooter)
      const tempMenuRedeSocial = await getMenusById(idMenuRedeSocial)

      setBanner(tempBanner.data)
      setMenuFooter(tempMenuFooter.data.menu_items)
      setMenuRedeSocial(tempMenuRedeSocial.data.menu_items)
    }

    getData()
  }, [])

  const getIconClass = (type) => {
    return `footer-icon footer-icon-${type}`
  }

  const handleIconClick = (event, url) => {
    event.preventDefault()
    window.open(url, '_blank')
  }

  return (
    <div className='footer'>
      <div className='footer-container'>
        {banner.file && (
          <Link
            className='footer-logo'
            to={banner.url}
            style={{ backgroundImage: `url(${banner.file.urlPublica})` }}
          />
        )}

        <div className='footer-menu'>
          {menuFooter.length > 0 && (
            <div className='footer-links'>
              {menuFooter.map((item, index) => (
                <Link className='footer-link' to={item.url} key={index}>
                  {item.title}
                </Link>
              ))}

              {!getToken()
                ? (
                  <div className='footer-link' onClick={() => redirectToLogin()}>
                    Login
                  </div>
                )
                : (
                  <div className='footer-link' onClick={() => redirectToLogin()}>
                    Sair
                  </div>
                )}
            </div>
          )}

          {menuRedeSocial.length > 0 && (
            <div className='footer-icons'>
              {menuRedeSocial.map((item, index) => (
                <div className={getIconClass(item.title)} key={index} onClick={(e) => handleIconClick(e, item.url)}>
                  <img src={item.icon} alt={menuRedeSocial.title} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Footer
