import React, { useState } from 'react'
import { stripTags } from '../../../utils/manipulateString'
import './styles.css'

const BannerLeft = ({ content }) => {
  const [banner] = useState(content)

  // setTimeout(() => {
  //   const bannerLeftImg = document.querySelector('.banner-left-image')

  //   if (bannerLeftImg) {
  //     bannerLeftImg.classList.add('banner-left-image-animate')
  //   }
  // }, 1000)

  return (
    <>
      {banner && (
        <div className='banner-left'>
          {banner.file && (
            <div className='banner-left-image-container'>
              <img
                className='banner-left-image'
                src={banner.file.urlPublica}
                alt=''
              />
            </div>
          )}
          <div className='banner-left-info'>
            <p className='sobre-banner-left'>sobre</p>
            <div
              className='banner-left-title'
              dangerouslySetInnerHTML={{ __html: stripTags(banner.title) }}
            />
            <div
              className='banner-left-description'
              dangerouslySetInnerHTML={{ __html: stripTags(banner.description) }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default BannerLeft
