import React, { useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'

import './styles.css'
import { stripTags } from '../../utils/manipulateString'

const Faq = ({ content }) => {
  const [faqs] = useState(content)

  const handleClick = (idAnswer, idIcon) => {
    const answer = document.getElementById(idAnswer)
    const icon = document.getElementById(idIcon)
    answer.classList.toggle('visible')
    icon.classList.toggle('rotate-180')
  }

  return (
    <>
      {faqs.length > 0 && (
        <div className='faq-page'>
          <div className='faq-container'>
            <div className='faq-content'>
              <div className='faq-content-container'>
                {faqs.map((faq, index) => (
                  <div className='faq-content-item' key={index}>
                    <div className='faq-item-question' onClick={() => handleClick(`answer-${index}`, `icon-${index}`)}>
                      <h4 className='question-container'>
                        <div
                          className='question'
                          dangerouslySetInnerHTML={{ __html: stripTags(faq.question) }}
                        />
                      </h4>
                      <IoIosArrowDown className='icon' id={`icon-${index}`} />
                    </div>
                    <div className='answer-container' id={`answer-${index}`}>
                      <div
                        className='answer'
                        dangerouslySetInnerHTML={{ __html: stripTags(faq.answer) }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      )}
    </>
  )
}

export default Faq
