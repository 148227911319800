import styled from 'styled-components';

export const ProfileBox = styled.div`
  padding: 10px;
  border: 1px solid #f4f1f1;
  border-radius: 5px;
  width: 100%;
  max-width: 420px;
  margin: 160px 0 30px;
  
  h1{
    margin: 20px 0 10px;
    font-family: 'House Slant', sans-serif;
    color: #211e2a;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
    text-align: center;
  }
  
  .element-field {
    margin-bottom: 10px;

    .label-title {
      font-weight: 300;
      display: block;
      margin-bottom: 5px;
    }
    
    .form-control {
      border: 1px solid #ccc;
      font-size: 14px;
      padding: 8px 12px;
      height: 38px;
      display: block;
      width: 100%;
      border-radius: 5px;
    }
    
    span {
      color: #f00;
      font-size: 12px;    
    } 
  }
  
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .btn{
    border: 0;
    display: flex;
    cursor: pointer;
    background: #211e2a;
    color: #ffffff;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    max-width: 170px;
    &:hover{
      background-color: #cc2f48;
    }
  }
`;
