import api from '../../api'
import { getCurrentUser } from '../../../utils/getUser'

export const getMe = () => {
  return api.get('/users/me')
}

export const updateCurrentUser = (params) => {
  return api.put(`/users/${getCurrentUser().id}`, params)
}

export const changePasswordUser = (params) => {
  return api.post(`/users/${getCurrentUser().id}/change_password`, params)
}
