import api from '../../api'

export const getFavorites = () => {
  return api.get('/general/evaluations?match[content_iri]=/lms/courses&type=FAVORITE')
}

export const getFavoriteByCourseAndUserId = (userId, courseId) => {
  return api.get(`/general/evaluations?match[content_iri]=/lms/courses/${courseId}&type=FAVORITE&user_iri=/users/${userId}`)
}

export const updateFavorites = (favorite) => {
  return api.post('/general/evaluations', favorite)
}
