import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import './styles.css'

const Forum = ({ children, page }) => {
  return (
    <>
      <Breadcrumbs
        pages={[
          {
            title: page.title,
            url: page.slug
          }
        ]}
      />
      <div className='forum-page'>
        {children}
      </div>
    </>
  )
}

export default Forum
