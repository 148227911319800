import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './styles.css'
import { stripTags } from '../../../utils/manipulateString'

const Beneficios = ({ content }) => {
  const [beneficios] = useState(content.features || [])

  return (
    <>
      {beneficios.length > 0 && (
        <div className='beneficios'>
          {beneficios.map((beneficio, index) => {
            if (index % 2 === 0) {
              return (
                <div className='beneficio' key={index}>
                  {beneficio.image && (
                    <img className='beneficio-img' src={beneficio.image.urlPublica} alt='' />
                  )}
                  <div className='beneficio-info'>
                    <Link className='beneficio-info-title' to={beneficio.link}>{beneficio.title}</Link>
                    <div
                      className='beneficio-info-description'
                      dangerouslySetInnerHTML={{
                        __html: stripTags(beneficio.description)
                      }}
                    />
                  </div>
                </div>
              )
            } else {
              return (
                <div className='beneficio' key={index}>
                  <div className='beneficio-info'>
                    <Link className='beneficio-info-title' to={beneficio.link}>{beneficio.title}</Link>
                    <div
                      className='beneficio-info-description'
                      dangerouslySetInnerHTML={{
                        __html: stripTags(beneficio.description)
                      }}
                    />
                  </div>
                  {beneficio.image && (
                    <img className='beneficio-img' src={beneficio.image.urlPublica} alt='' />
                  )}
                </div>
              )
            }
          })}
        </div>
      )}
    </>
  )
}

export default Beneficios
