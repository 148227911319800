import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { updateFavorites } from '../../services/endpoints/general/evaluations'
import { getCurrentUser } from '../../utils/getUser'
import { getFavoritesActive } from '../../utils/verifyActive'

import Curso from '../Cursos/Curso'
import Carousel from '../Carousel'

import './styles.css'
import '../Cursos/styles.css'

const Favorites = ({ content }) => {
  const [favorites] = useState(getFavoritesActive(content))
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth)
  const smallDevice = 642

  window.addEventListener('resize', () => {
    setWindowWidth(document.documentElement.clientWidth)
  })

  const removeFavorite = async (event, courseId) => {
    event.preventDefault()

    const userId = getCurrentUser().id
    const req = {
      user_iri: `/users/${userId}`,
      content_iri: `/lms/courses/${courseId}`,
      value: '1',
      type: 'FAVORITE'
    }

    try {
      await updateFavorites(req)
      window.location.reload(false)
    } catch (error) {
      // eslint-disable-next-line
      alert(`Não foi possível remover o curso dos favoritos. ${error}`)
    }
  }

  return (
    <div className='cursos-container'>
      {favorites.length > 0 &&
      (windowWidth > smallDevice
        ? (
          <div className='cursos'>
            {favorites.map(
              (favorite, index) =>
                favorite.content && (
                  <Curso curso={favorite.content} key={index}>
                    <IoMdClose
                      className='remove-favorite-icon'
                      onClick={(e) => removeFavorite(e, favorite.content.id)}
                    />
                  </Curso>
                )
            )}
          </div>
        ) : (
          <Carousel
            list={favorites.map(
              (favorite, index) =>
                favorite.content && (
                  <Curso curso={favorite.content} key={index}>
                    <IoMdClose
                      className='remove-favorite-icon'
                      onClick={(e) => removeFavorite(e, favorite.content.id)}
                    />
                  </Curso>
                )
            )}
          />
        ))}
    </div>
  )
}

export default Favorites
