import React, { useState } from 'react'

import { getCoursesActive } from '../../utils/verifyActive'

import Curso from './Curso'
import Carousel from '../Carousel'
import './styles.css'

const Cursos = ({ content }) => {
  const [cursos] = useState(getCoursesActive(content))
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth)
  const smallDevice = 642

  window.addEventListener('resize', () => {
    setWindowWidth(document.documentElement.clientWidth)
  })
  return (
    <>
      {cursos && cursos.length > 0 && (
        <div className='cursos-container'>
          {windowWidth > smallDevice
            ? (
              <div className='cursos'>
                {cursos.map((curso, index) => (
                  <Curso curso={curso} key={index} />
                ))}
              </div>
            ) : (
              <Carousel list={cursos.map((curso, index) => (
                <Curso curso={curso} key={index} />
              ))}
              />
            )}
        </div>
      )}
    </>
  )
}

export default Cursos
