import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'

const Logo = ({ logo = {} }) => {
  const { url, file } = logo
  return (
    <>
      {url && file && file.urlPublica &&
        <Link
          className='navbar-logo'
          to={url}
          style={{ backgroundImage: `url(${file.urlPublica})` }}
        />}
    </>
  )
}

export default Logo
