import React, { useState } from 'react'
import './styles.css'

const AulasInfo = ({ lessons = [] }) => {
  const [qtdLesson] = useState(lessons.length)

  return (
    <div className='curso-show-info-aulas'>
      <p className='curso-show-info-title'>Aulas</p>
      <p className='curso-show-info-aulas-qtd'>
        {qtdLesson}
      </p>
    </div>
  )
}

export default AulasInfo
