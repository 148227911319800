import React from 'react'

import { stripTags } from '../../../utils/manipulateString'

import ContentMedia from '../../ContentMedia'
import BlockedContentLayer from '../../BlockedContent/BlockedContentLayer'

import './styles.css'

const ActualLesson = ({ lesson = {} }) => {
  const { lesson_contents, title, description } = lesson
  return (
    <>
      {lesson &&
        <div className='curso-show-actual-lesson-container'>
          <div className='curso-show-actual-lesson-content-container'>
            <BlockedContentLayer />
            {lesson_contents && lesson_contents.length > 0
              ? <ContentMedia id='content-media' video={lesson.lesson_contents[0]} />
              : <h1 className='curso-show-title'>Nenhuma aula disponível</h1>}
          </div>

          <div className='curso-show-actual-lesson-info'>
            {title &&
              <div
                className='curso-show-actual-lesson-title' dangerouslySetInnerHTML={{ __html: stripTags(title) }}
              />}
            {description &&
              <div
                className='curso-show-actual-lesson-description' dangerouslySetInnerHTML={{ __html: stripTags(description) }}
              />}
          </div>
        </div>}
    </>
  )
}

export default ActualLesson
