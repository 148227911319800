import React, { useState } from 'react'
import { sendContact } from '../../services/endpoints/cms/contact'
import { getCurrentUser } from '../../utils/getUser'

import './styles.css'

const Contato = ({ content }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
    const form = document.querySelector('.contato-form-container')
    const succeedMessage = document.querySelector('.contato-successful-submission')
    const failedMessage = document.querySelector('.contato-failed-submission')

    form.style.display = 'none'

    try {
      const contact = {
        content: `<p>${message}</p> <p>&nbsp;</p>\r\n<p> Nome: <b>${name}</b>,</p>\r\n <p> E-mail: <b>${email}</b></p>`,
        subject: subject,
        user_iri: getCurrentUser() ? getCurrentUser().id : null,
        ip: '0.0.0.0',
        user_agent: navigator.userAgent

      }
      await sendContact(contact)

      succeedMessage.style.display = 'block'
    } catch (error) {
      failedMessage.style.display = 'block'
    }
  }

  return (
    <div className='contato'>
      <h1 className='contato-title'>Envie sua dúvida</h1>
      <div className='contato-form-container'>
        <form className='contato-form' onSubmit={(e) => handleSubmit(e)}>
          <label className='contato-label'>
                      Seu nome
            <input className='contato-input-string' type='text' name='name' required onChange={e => setName(e.target.value)} />
          </label>
          <label className='contato-label'>
                      E-mail
            <input className='contato-input-string' type='email' name='email' required onChange={e => setEmail(e.target.value)} />
          </label>
          <label className='contato-label'>
                      Assunto
            <input className='contato-input-string' type='text' name='subject' required onChange={e => setSubject(e.target.value)} />
          </label>
          <label className='contato-label'>
                      Mensagem
            <textarea className='contato-input-text' type='text' name='message' required onChange={e => setMessage(e.target.value)} />
          </label>

          <div className='contanto-button-container'>
            <input className='contato-button' type='submit' value='Enviar Mensagem' />
          </div>
        </form>
      </div>
      <div className='contato-successful-submission'>
        <p>Obrigado! Sua mensagem foi recebida!</p>
      </div>
      <div className='contato-failed-submission'>
        <p>Oops! Aconteceu algum erro durante o envio da mensagem.</p>
      </div>
    </div>
  )
}

export default Contato
