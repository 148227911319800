import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { IoMdDownload } from 'react-icons/io'
import { getToken } from '../../services/auth'

import './styles.css'
import NoProfile from '../../assets/images/no-profile-picture.png'

const Certificados = ({ content }) => {
  const [courses] = useState(content)

  function getCertificado (e, courseId) {
    e.preventDefault()
    axios({
      url: `${process.env.REACT_APP_API_URL}/lms/courses/${courseId}/certificate_issuance`,
      method: 'GET',
      responseType: 'arraybuffer', // important
      headers: { Authorization: getToken() }
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      )
      window.open(url, '_blank')
    })
  }

  return (
    <>
      {courses && courses.length > 0 && (
        <div className='certificados-container'>
          <div className='certificados'>
            {courses.map(
              (course, index) =>
                course.course &&
                course.course.active && (
                  <div className='certificado' key={index}>
                    {course.course.image ? (
                      <div className='certificado-image'>
                        <img
                          src={
                            course.course.image.urlPublica !== ''
                              ? course.course.image.urlPublica
                              : NoProfile
                          }
                          alt=''
                        />
                      </div>
                    ) : (
                      <div className='certificado-image'>
                        <img src={NoProfile} alt='' />
                      </div>
                    )}
                    <p className='certificado-type'>Curso</p>
                    {course.course.slug && (
                      <Link
                        className='certificado-title'
                        to={`/cursos/${course.course.slug}`}
                      >
                        {course.course.title}
                      </Link>
                    )}
                    <div className='certificado-progress'>
                      <p>Progresso</p>
                      <div className='certificado-progress-draw' />
                      <p>100%</p>
                    </div>
                    <p>
                      Conclusão:{' '}
                      {course.completion_at &&
                        course.completion_at.split(' ')[0]}
                    </p>
                    {course.course.id &&
                      <div
                        className='certificado-baixar'
                        onClick={(e) => getCertificado(e, course.course.id)}
                      >
                        <p>Baixar</p>
                        <IoMdDownload className='certificado-baixar-icon' />
                      </div>}
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Certificados
