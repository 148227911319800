import React from 'react'
import AvatarUpload from '../../components/AvatarUpload'
import { ProfileBox } from './styled'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Breadcrumbs from '../../components/Breadcrumbs'

import { getCurrentUser } from '../../utils/getUser'
import * as Yup from 'yup'
import GenerateForm from '../../components/GenerateForm'
import { splitFullname } from '../../utils/manipulateString'
import { getFieldValues, setFieldsValues } from '../../services/endpoints/general/field_values'
import { getFields } from '../../services/endpoints/general/fields'
import { mapInitialValues } from '../../components/GenerateForm/util/mapInitialValues'
import {
  changePasswordUser,
  updateCurrentUser
} from '../../services/endpoints/general/users'

const Profile = (props) => {
  const user = getCurrentUser()
  const { profile } = user

  const groupFields = [
    {
      name: '',
      fields: [
        {
          label: 'Primeiro nome',
          hasPlaceholder: false,
          name: 'firstname',
          value: splitFullname(user.name).firstname,
          required: true,
          validations: {
            firstname: Yup.string().required('Campo obrigatório')
          }
        },
        {
          label: 'Último nome',
          hasPlaceholder: false,
          name: 'lastname',
          value: splitFullname(user.name).lastname,
          required: true,
          validations: {
            lastname: Yup.string().required('Campo obrigatório')
          },
          mask: ''
        },
        {
          label: 'Celular',
          hasPlaceholder: false,
          name: 'cellphone',
          value: profile.cellphone ? profile.cellphone.value : '',
          required: true,
          validations: {
            cellphone: Yup.string().required('Campo obrigatório')
          },
          mask: '+55 (99) 99999-9999',
          maskType: 'phone'
        },
        {
          label: 'Senha',
          hasPlaceholder: false,
          type: 'password',
          name: 'plainPassword',
          value: '',
          required: true,
          validations: {
            plainPassword: Yup.string()
              .min(8, 'Senha com no mínimo 8 caracteres')
              .max(24, 'Senha com no máximo 24 caracteres')
              .matches(/[a-z]/, 'Pelo menos um caractere minúsculo')
              .matches(/[A-Z]/, 'Pelo menos um caractere maiúsculo')
              .matches(/[0-9]/, 'Pelo menos um número')
              .matches(/[@$!%*#?&.<>]/, 'Pelo menos um caractere especial ex: @,$,!,%...')
          },
          mask: ''
        },
        {
          label: 'Confirmar Senha',
          hasPlaceholder: false,
          type: 'password',
          name: 'confirmPassword',
          value: '',
          required: true,
          validations: {
            confirmPassword: Yup.string().when('plainPassword', {
              is: val => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('plainPassword')], 'As senhas não são iguais')
                .required('Campo obrigatório')
            })
          },
          mask: ''
        }
      ]
    }
  ]

  const onSubmit = async (values, actions) => {
    try {
      // tratamentos
      Object.assign(values, {
        cellphone: values.cellphone.replace(/\D/g, '')
      })

      // init map to java project
      const fields = await getFields()
      const fieldValues = await getFieldValues()
      const fieldValuesData = {}
      fieldValues.data.forEach(field => Object.assign(fieldValuesData, {
        [field.field.title]: field.id
      }))

      const myFieldsValues = mapInitialValues(groupFields)
      const dataValues = []

      fields.data.forEach((field) => {
        if (Object.keys(myFieldsValues).includes(field.title)) {
          const obj = {
            fieldId: field.id,
            value: values[field.title]
          }
          if (fieldValuesData[field.title]) {
            Object.assign(obj, {
              id: fieldValuesData[field.title]
            })
          }
          dataValues.push(obj)
        }
      })
      // end map java project

      await updateCurrentUser({
        name: `${values.firstname} ${values.lastname}`
      })

      // save values in fieldvalues
      await setFieldsValues(dataValues)

      // save password if exits
      if (values.plainPassword.length >= 8) {
        await changePasswordUser({
          plainPassword: values.plainPassword
        })
      }
      actions.setSubmitting(false)
    } catch (e) {
      console.error('Error Profile.onSubmit', e)
      alert('Ocorreu um erro ao salvar os seus dados, tente novamente mais tarde')
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <Navbar />
      <Breadcrumbs
        pages={[
          {
            title: 'Perfil',
            url: '/perfil'
          }
        ]}
      />
      <div className='container'>
        <ProfileBox>
          <h1>Meus dados</h1>

          <AvatarUpload user={user} />

          <GenerateForm groupFields={groupFields} onSubmit={onSubmit} />

        </ProfileBox>
      </div>
      <Footer />
    </>
  )
}

export default Profile
