import React, { useState, useEffect } from 'react'
import { getForumUrl, getFlarumCookie } from '../../services/endpoints/settings'
import './styles.css'

const Forum = () => {
  const [forumUrl, setForumUrl] = useState('')

  useEffect(() => {
    const updateForumUrl = async () => {
      const allResponse = await Promise.all([getFlarumCookie(), getForumUrl()])

      const responseForumCookie = allResponse[0]
      document.cookie = `${responseForumCookie.data.userCookie.split(';')[0]}; Domain=${process.env.REACT_APP_DOMAIN}`
      document.cookie = `locale=pt-BR; Domain=${process.env.REACT_APP_DOMAIN}`

      const responseForumUrl = allResponse[1]
      setForumUrl(responseForumUrl.value)
    }
    updateForumUrl()
  }, [])

  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
  const eventer = window[eventMethod]
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

  // Listen to message from fórum iframe
  eventer(messageEvent, function (e) {
    if (e.data === 'true' && window.innerWidth > 771) {
      window.scrollTo({ top: document.body.scrollHeight || document.documentElement.ScrollHeight, behavior: 'smooth' })
    }
  }, false)

  return (
    <>
      {forumUrl &&
        <div className='forum-container'>
          <iframe crossOrigin='true' className='forum-iframe' src={forumUrl} id='forum' title='forum' />
        </div>}
    </>
  )
}

export default Forum
