import axios from 'axios'
import Cookie from 'js-cookie'
import { getToken, signinRedirect } from './auth'

const apiUrl = process.env.REACT_APP_API_URL
const params = getToken() ? ({
  baseURL: apiUrl,
  headers: { Authorization: getToken() }
}) : ({
  baseURL: apiUrl
})

const api = axios.create(params)

api.interceptors.response.use((response) => response, (error) => {
  const status =
      error.response && error.response.hasOwnProperty('status')
        ? error.response.status
        : error

  switch (status) {
    case 401:
      Cookie.remove(process.env.REACT_APP_TOKEN)
      break

    case 403:
      signinRedirect(error.response.data.reason)
      break
    case 405:
      console.log('Method Not Allowed')
      break
    case 406:
      console.log('Not Acceptable')
      break
    case 422:
      console.log('Unprocessable Entity')
      break
    default:
      console.log(`Error ${status}`)
  }

  return Promise.reject(error)
}
)

export default api
