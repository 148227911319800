import React, { useState, useEffect } from 'react'

import { getToken } from '../../services/auth'
import { getMenusById } from '../../services/endpoints/cms/menus'
import { getBannersById } from '../../services/endpoints/cms/banners'

import Logo from './Logo'
import Search from './Search'
import Menu from './Menu'

import './styles.css'

const idMenu = 4
const idBanner = 18

const Navbar = () => {
  const [menu, setMenu] = useState([])
  const [banner, setBanner] = useState('')

  useEffect(() => {
    const getData = async () => {
      const responseGetMenus = await getMenusById(idMenu)
      const responseGetBanners = await getBannersById(idBanner)
      const menu = await (getToken() ? responseGetMenus.data.menu_items
        : responseGetMenus.data.menu_items.filter(menu => (
          menu.title !== 'Fórum'
        ))

      )
      setMenu(menu)
      setBanner(responseGetBanners.data)
    }
    getData()
  }, [])

  return (
    <div className='navbar'>
      <div className='navbar-container'>
        {banner && (
          <Logo logo={banner} />
        )}
        <Search />

        {menu.length > 0 && (
          <Menu menu={menu} />
        )}

      </div>
      <div className='container-layer' />
    </div>
  )
}

export default Navbar
