import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import NoProfile from '../../assets/images/no-profile-picture.png'
import './styles.css'
import { stripTags } from '../../utils/manipulateString'

const Trilhas = ({ content }) => {
  const [trilhas] = useState(content)

  return (
    <>
      {trilhas && trilhas.length > 0 &&
      (
        <div className='trilhas-container'>
          <div className='trilhas'>
            {trilhas.map((trilha, index) =>
              trilha.trails_courses && trilha.trails_courses.length > 0
                ? (
                  <div className='trilha' key={index}>
                    {trilha.trails_courses[0].course &&
                      <div className='trilha-image'>
                        <img src={trilha.trails_courses[0].course.image.urlPublica} alt='' />
                      </div>}
                    <Link
                      className='trilha-title'
                      to={`/trilhas/${trilha.id}`}
                    >
                      {trilha.title}
                    </Link>
                    <p
                      className='trilha-text'
                      dangerouslySetInnerHTML={{ __html: stripTags(trilha.trails_courses[0].course.summary) }}
                    />
                    <div className='divider' />
                    <Link
                      className='trilha-saiba-mais'
                      to={`/trilhas/${trilha.id}`}
                    >
                      SAIBA MAIS
                    </Link>
                  </div>
                ) : (
                  <div className='trilha' key={index}>
                    <div className='trilha-image'>
                      <img src={NoProfile} alt='' />
                    </div>
                    <Link
                      className='trilha-title'
                      to={`/trilhas/${trilha.id}`}
                    >
                      {trilha.title}
                    </Link>
                    <p className='trilha-text'>Nenhum curso disponível.</p>
                    <div className='divider' />
                    <Link
                      className='trilha-saiba-mais'
                      to={`/trilhas/${trilha.id}`}
                    >
                      SAIBA MAIS
                    </Link>
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Trilhas
