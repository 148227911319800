import React, { useState } from 'react'

import ActualLesson from './ActualLesson'
import InfoActualCourse from './InfoActualCourse'
import MenuActualCourse from './MenuActualCourse'
import HandleViewContent from '../ContentMedia/handleViewContent'
import VerifyStatsContext from './VerifyStatsContext'

import './styles.css'

const CursoAtual = ({ actualCourse }) => {
  const [course] = useState(actualCourse)
  const [actualLesson, setActualLesson] = useState(0)

  const verifyStats = async (newActualLesson = null) => {
    const validIndex = newActualLesson !== null && newActualLesson !== undefined
    validIndex && setActualLesson(newActualLesson)
    window.scrollTo({ top: 0 })
  }

  return (
    <>
      {course && course.active && course.lessons &&
        <div className='curso-atual'>
          {course.lessons.length > 0 &&
            <>
              <HandleViewContent.Provider value={{ verifyStats }}>
                <ActualLesson lesson={course.lessons[actualLesson]} />
              </HandleViewContent.Provider>

              <InfoActualCourse course={course} actualLesson={actualLesson} />

              <VerifyStatsContext.Provider value={{ verifyStats }}>
                <MenuActualCourse course={course} actualLesson={actualLesson} />
              </VerifyStatsContext.Provider>
            </>}
        </div>}
    </>
  )
}

export default CursoAtual
