import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import { stripTags } from '../../utils/manipulateString'

const PageDefault = ({ children, page }) => {
  return (
    <>
      <Breadcrumbs
        pages={[
          {
            title: page.title,
            url: page.slug
          }
        ]}
      />
      <div className='page-container'>
        {page.title &&
          <h1 className='page-container-title'>{page.title}</h1>}
        {page.content &&
          <div className='page-container-content' dangerouslySetInnerHTML={{ __html: stripTags(page.content) }} />}
        {page.image &&
          <img src={page.imag.urlPublica} alt='' />}
        {page.thumbnail &&
          <img src={page.thumbnail.urlPublica} alt='' />}

        {children}
      </div>
    </>
  )
}

export default PageDefault
