import React from 'react'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowRight } from 'react-icons/md'
import './styles.css'

const Breadcrumbs = ({ pages }) => {
  return (
    <div className='breadcrumbs-container'>
      <Link className='breadcrumbs-link' to='/'>
        Home
      </Link>

      {pages.map((page, index) => (
        <div className='breadcrumbs-path' key={`breadcrumbs-${index}`}>
          {page.url ? (
            <>
              <div className='breadcrumbs-icon-container'>
                <MdKeyboardArrowRight className='breadcrumbs-icon' />
              </div>
              <Link className='breadcrumbs-link' to={page.url}>
                {page.title}
              </Link>
            </>
          ) : (
            <>
              <div className='breadcrumbs-icon-container'>
                <MdKeyboardArrowRight className='breadcrumbs-icon' />
              </div>
              <p className='breadcrumbs-link'>{page.title}</p>
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default Breadcrumbs
