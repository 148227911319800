import React from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowDown } from 'react-icons/io'
import { redirectToLogin } from '../../../../services/auth'
import './styles.css'

const Dropdown = ({ title, childrens }) => {
  return (
    <div className='dropdown'>
      <div className='dropdown-title'>
        {title}
        <IoIosArrowDown className='dropdown-title-icon' />
      </div>

      <ul className='dropdown-list'>
        {childrens.map((children, index) => (
          <li key={index}>
            {children.url && children.title ? (
              <Link
                className='dropdown-link'
                to={(e) => children.url}
                target={children.target ? children.target : '_self'}
              >
                {children.title}
              </Link>
            ) : (
              <div
                className='dropdown-link'
                onClick={(e) => redirectToLogin(e)}
              >
                {children.title}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Dropdown
