import React from 'react';
import MaskedInput from 'react-input-mask';
import { Field } from 'formik';
import { makeMask } from './util/makeMask';

const MakeField = ({ type, ...el }) => {

  switch (type) {
    case 'select' :
      return (
        <Field
          as="select"
          className="form-control form-select"
          name={el.name}
          id={el.name}
          placeholder={el.hasPlaceholder ? el.label : ''}
        >
          {el.options.map(option => (
            <option key={`option-${option.value}`} value={option.value}>{option.label}</option>
          ))}
        </Field>
      );
    case 'textarea' :
      return (
        <Field
          as="textarea"
          className="form-control form-textarea"
          name={el.name}
          id={el.name}
          placeholder={el.hasPlaceholder ? el.label : ''}
        />
      );
    default:
      if (el.mask) {
        return (
          <Field name={el.name} element={el}>
            {({ field }) => {
              return (
                <MaskedInput
                  {...field}
                  id={el.name}
                  className="form-control"
                  placeholder={el.hasPlaceholder ? el.label : ''}
                  maskChar=""
                  mask={makeMask(field.value, el.mask, el.maskType)}
                />
              );
            }}
          </Field>
        )
      }

      return (
        <Field
          type={type||'text'}
          className="form-control"
          name={el.name}
          id={el.name}
          placeholder={el.hasPlaceholder ? el.label : ''}
        />
      )
  }
};

export default MakeField;
